<template>
  <!-- 面包屑 -->
  <a-breadcrumb>
    <a-breadcrumb-item>项目配置</a-breadcrumb-item>
    <a-breadcrumb-item>配置管理</a-breadcrumb-item>
  </a-breadcrumb>
  <!-- 功能条 -->
  <div class="tool-view">
    <!-- 名称 -->
    <span class="tool-title">名称：</span>
    <a-input v-model:value="search" style="width: 160px;" placeholder="请输入" />
    <!-- 配置类型 -->
    <span class="tool-title">配置类型：</span>
    <a-select v-model:value="payType" style="width: 160px;" placeholder="请选择">
      <a-select-option :value="0">全部</a-select-option>
      <a-select-option v-for="item in ConfigTypes" :key="item.value" :value="item.value">{{ item.text }}</a-select-option>
    </a-select>
    <!-- 支付渠道 -->
    <span class="tool-title">支付渠道：</span>
    <a-select v-model:value="payChannel" style="width: 160px;" placeholder="请选择">
      <a-select-option :value="0">全部</a-select-option>
      <a-select-option v-for="item in PayChannels" :key="item.value" :value="item.value">{{ item.text }}</a-select-option>
    </a-select>
    <!-- 操作 -->
    <a-button type="primary" style="margin-left: 16px;" @click="handleSearch">查询</a-button>
    <a-button style="margin-left: 16px;" @click="handleReset">重置</a-button>
    <div style="flex: 1;"></div>
    <a-button type="primary" @click="add()"><plus-outlined />新增配置</a-button>
  </div>
  <!-- 列表 -->
  <a-table
    class="table-view"
    :data-source="dataSource"
    :columns="columns"
    :row-key="record => record.id"
    :pagination="pagination"
    :loading="isLoading"
    @change="handleTableChange"
  >
    <!-- 自定义行内容 -->
    <template #bodyCell="{ column, record }">
      <!-- 支付类型 -->
      <template v-if="column.key === 'type'">
        {{ (ConfigTypes.find(item => item.value === record[column.key]) || {}).text }}
      </template>
      <!-- 支付渠道 -->
      <template v-if="column.key === 'pay_channel'">
        {{ (PayChannels.find(item => item.value === record[column.key]) || {}).text || '-' }}
      </template>
      <!-- 创建时间 -->
      <template v-if="column.key === 'created_at'">
        {{ record.created_at && dayjs(record.created_at).format('YYYY-MM-DD') }}
      </template>
      <!-- 操作 -->
      <template v-if="column.key === 'operation'">
        <a class="operation-item" @click="add(record)">修改</a>
        <a class="operation-item" @click="copyCreate(record)">复制创建</a>
      </template>
    </template>
  </a-table>
  <!-- 新增、编辑项目设置 -->
  <Add ref="RefAdd" @success="getProjectConfigList"></Add>
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue'
import dayjs from 'dayjs'
import Add from './components-config/Add'
import { message } from 'ant-design-vue'
import { ConfigTypes, PayChannels } from '@/utils/constantList'
import { projectConfigList } from '@/api/system'

// 新增
let RefAdd = ref(null)
// 加载
let isLoading = ref(false)
// 数据源
let dataSource = ref([])
// 搜索
let search = ref(undefined)
// 支付类型
let payType = ref(undefined)
// 支付渠道
let payChannel = ref(undefined)
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源
let columns = ref([
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: '名称',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '配置类型',
    dataIndex: 'type',
    key: 'type'
  },
  {
    title: '支付渠道',
    dataIndex: 'pay_channel',
    key: 'pay_channel'
  },
  {
    title: '创建日期',
    dataIndex: 'created_at',
    key: 'created_at'
  },
  {
    title: '备注',
    dataIndex: 'remark',
    key: 'remark'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation'
  }
])

// 钩子函数
onBeforeMount (() => {
  // 获取项目列表
  getProjectConfigList()
})

// 查询
function handleSearch () {
  pagination.current = 1
  // 获取列表
  getProjectConfigList()
}

// 重置
function handleReset () {
  // 筛选项重置
  search.value = null
  payType.value = undefined
  payChannel.value = undefined
  // 分页重置
  pagination.current = 1
  getProjectConfigList()
}

// 获取项目列表
function getProjectConfigList () {
  isLoading.value = true
  const params = {
    kw: search.value,
    type: payType.value,
    pay_channel: payChannel.value,
    page: pagination.current,
    page_size: pagination.pageSize
  }
  projectConfigList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      dataSource.value = data.data
      pagination.total = data.total
    } else {
      message.error(res.message || msg)
    }
  })
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getProjectConfigList()
}

// 新增配置
function add (record) {
  RefAdd.value.showDrawer(record || {})
}

// 复制创建
function copyCreate (record) {
  RefAdd.value.showDrawer(record || {}, true)
}
</script>

<style scoped>
.tool-view {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.table-view {
  margin-top: 20px;
}
.tool-title:not(:first-child) {
  margin-left: 16px;
}
.operation-item {
  margin-right: 8px;
}
</style>